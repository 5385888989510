import "./styles.scss";
import React from "react";

import SectionHeader from "components/SectionHeader";
import CircleIcon from "components/CircleIcon";

import ProfitIcon from "icons/features/profit.svg";
import KyriadIcon from "icons/features/kyriad.svg";
import CalendarIcon from "icons/features/calendar-search.svg";
import RRIcon from "icons/rr.svg";

const mainClass = "home-foundations";

const business_model_data = [
  {
    icon: <KyriadIcon />,
    title: "Rozpoznawalność marki Kyriad i siła sieci Louvre Hotels Group",
    text: "Kyriad to sieć ponad 260 trzygwiazdkowych hoteli we Francji i na całym świecie. Właścicielem marki jest Louvre Hotels Group - główny gracz w światowej branży hotelarskiej. Portfolio sieci obejmuje ponad 1600 hoteli w 54 krajach. ",
  },
  {
    icon: <ProfitIcon />,
    title: "KaiZEN",
    text: [
      "Operatora tworzy zespół ekspertów z ponad 20-letnim doświadczeniem na polskim rynku hotelowym i condohotelowym.",
      "Jako jedni z pierwszych wprowadzaliśmy system condohotelowy w Polsce w takich inwestycjach jak: Sand Hotel, Marine Hotel, Ultra Marine Hotel czy Nosalowy Dwór.",
      "Obecnie skupiamy się na tworzeniu marki własnej.",
    ],
  },
  {
    icon: <CalendarIcon />,
    title: "Wysoki popyt i zainteresowanie Twoim produktem",
    text: "Hotel położony jest w atrakcyjnej lokalizacji, w regionie odwiedzanym przez 1,5 mln turystów rocznie",
  },
];

const Foundations = () => {
  return (
    <section className={mainClass}>
      <div className="container">
        <SectionHeader title="Fundamenty pewnej inwestycji" />
        <div className={`${mainClass}__content`}>
          <div className={`${mainClass}__content__left`}>
            <div className={`${mainClass}__content__item`}>
              <CircleIcon icon={<RRIcon />} variant="white" />
              <div className={`${mainClass}__content__item__text`}>
                <label>Doświadczony deweloper</label>
                <span>
                  Rodzinne Resorty to deweloper specjalizujący się w budowie i
                  zarządzaniu projektami condohotelowymi na terenie całej
                  Polski. Naszą misją jest tworzenie funkcjonalnych i
                  nowoczesnych przestrzeni w wyjątkowych lokalizacjach, które
                  spełniają oczekiwania najbardziej wymagających klientów. Jako
                  deweloper zapewniamy pełne wsparcie w procesie zakupu,
                  urządzenia i zarządzania nieruchomością. Wyróżnia nas ponad
                  20-letnie doświadczenie w sprzedaży oraz zarządzaniu
                  projektami hotelowi i inwestycyjnymi, a także współpraca z
                  najlepszymi operatorami i wykonawcami na polskim rynku.
                  <br />
                  <br />
                  Zdajemy sobie sprawę z ogromnej roli branży deweloperskiej w
                  kształtowaniu obecnego świata, dlatego w codziennej
                  działalności deweloperskiej i budowlanej przywiązujemy
                  szczególną wagę do wykorzystania innowacyjnych ekologicznych
                  materiałów oraz realizacji projektów harmonijnie wpisujących
                  się w charakter okolicy oraz naturalne potrzeby branży
                  turystycznej i lokalnych społeczności.Starannie wybierane
                  lokalizacje w popularnych regionach, niski próg wejścia dla
                  klientów inwestycyjnych oraz atrakcyjne i funkcjonalne
                  projekty hotelarskie, to główne przewagi naszej oferty.
                </span>
              </div>
            </div>
          </div>
          <div className={`${mainClass}__content__right`}>
            {business_model_data?.map(({ icon, title, text }, index) => (
              <div key={index} className={`${mainClass}__content__item`}>
                <CircleIcon icon={icon} variant="white" />
                <div className={`${mainClass}__content__item__text`}>
                  <label>{title}</label>
                  {Array.isArray(text) ? (
                    text.map((item, index) => <span key={index}>{item}</span>)
                  ) : (
                    <span>{text}</span>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Foundations;
