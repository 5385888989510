import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "radio-button";

const RadioButton = ({
  label,
  type = "radio",
  value,
  onChange,
  name,
  input_value,
  icon,
  disabled,
}) => {
  const is_checked = value === input_value;

  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--active`]: !!is_checked,
        [`${mainClass}--icon`]: !!icon,
      })}
    >
      <label>
        {icon}
        {label}
        <input
          type={type}
          name={name}
          checked={!!is_checked}
          value={input_value}
          onChange={onChange}
          disabled={disabled}
        />
        {!!!icon && <span className="checkmark" />}
      </label>
    </div>
  );
};

export default RadioButton;
