import "./styles.scss";

import React from "react";
import classnames from "classnames";

import CircleIcon from "components/CircleIcon";

const mainClass = "features-grid";

const FeaturesGrid = ({ data, whiteIcons }) => {
  return (
    <div className={mainClass}>
      {data?.map(({ icon, title, text }, index) => (
        <div key={index} className={classnames(`${mainClass}__item`)}>
          <CircleIcon icon={icon} variant={whiteIcons ? "white" : null} />
          {!!title && <label>{title}</label>}
          <span>{text}</span>
        </div>
      ))}
    </div>
  );
};

export default FeaturesGrid;
