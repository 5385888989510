import statusRender from "./statusRender";

const filterApartments = ({
  apartments,
  rooms,
  area,
  floor,
  area_available,
}) => {
  let filterred_apartments = apartments;

  filterred_apartments = filterred_apartments?.map((item) => ({
    ...item,
    number: parseInt(item?.data?.nameBuilding?.replace(/^\D+/g, "")),
  }));

  const selelected_area = [
    parseFloat(
      area_available[area[0] - 1]?.data?.area ||
        area_available[area[1] - 1]?.data?.area
    ),
    parseFloat(area_available[area[1] - 1]?.data?.area),
  ];

  if (!!selelected_area) {
    filterred_apartments = filterred_apartments.filter(
      (item) =>
        item?.data?.area >= selelected_area?.[0] &&
        item?.data?.area <= selelected_area?.[1]
    );
  }

  if (!!floor) {
    filterred_apartments = filterred_apartments.filter(
      (item) =>
        item?.data?.floor >= floor?.[0] && item?.data?.floor <= floor?.[1]
    );
  }

  if (!!rooms) {
    filterred_apartments = filterred_apartments.filter(
      (item) =>
        item?.data?.rooms >= rooms?.[0] && item?.data?.rooms <= rooms?.[1]
    );
  }

  return filterred_apartments
    .filter(
      (apartment) => statusRender(apartment.data.statusId) !== "sprzedany"
    )
    .sort((a, b) => (a?.number < b?.number ? -1 : 1));
};

export default filterApartments;
