/* eslint-disable no-undef */
/* global google */
import "./styles.scss";

import React, { useState, useRef, useEffect } from "react";
import { withPrefix } from "gatsby";
import { compose, withProps } from "recompose";
import classnames from "classnames";

import markers from "libs/legend_data";

import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
  InfoWindow,
} from "react-google-maps";

import { MarkerWithLabel } from "react-google-maps/lib/components/addons/MarkerWithLabel";

const mapOptions = {
  styles: [
    {
      featureType: "landscape.man_made",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#8fc9b0",
        },
      ],
    },
    {
      featureType: "landscape.natural.landcover",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#ccf0e0",
        },
      ],
    },
    {
      featureType: "landscape.natural.terrain",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#587d6d",
        },
      ],
    },
    {
      featureType: "poi",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#C5E3BF",
        },
      ],
    },
    {
      featureType: "poi.park",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#264b3b",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry",
      stylers: [
        {
          lightness: 100,
        },
        {
          visibility: "simplified",
        },
      ],
    },
    {
      featureType: "road",
      elementType: "geometry.fill",
      stylers: [
        {
          color: "#d3dbc5",
        },
      ],
    },
    {
      featureType: "water",
      elementType: "geometry",
      stylers: [
        {
          visibility: "on",
        },
        {
          color: "#C6E2FF",
        },
      ],
    },
  ],
};

const LocationMap = compose(
  withProps({
    googleMapURL:
      "https://maps.googleapis.com/maps/api/js?key=AIzaSyDNFJB1acI3G5H1JBkWHjDYJgLkRAzA4Vg&v=3.exp&libraries=geometry,drawing,places",
    loadingElement: <div style={{ height: `100%` }} />,
    containerElement: (
      <div className="map-location" style={{ height: `600px` }} />
    ),
    mapElement: <div style={{ height: `100%` }} />,
  }),
  withScriptjs,
  withGoogleMap
)(() => {
  const ref = useRef();

  const [selected_marker, setSelectedMarker] = useState(null);

  useEffect(() => {
    const bounds = new google.maps.LatLngBounds();

    let all_markers = [];

    markers?.map(({ items }) =>
      items?.map(({ lat, lng }) => all_markers?.push({ lat, lng }))
    );

    for (let loc of all_markers)
      bounds.extend({
        lat: parseFloat(loc.lat),
        lng: parseFloat(loc.lng),
      });

    ref.current.fitBounds(bounds);
  }, [ref]);

  const icon = {
    url: withPrefix("/map-marker.png"),
  };

  return (
    <GoogleMap
      ref={ref}
      defaultOptions={{
        styles: mapOptions.styles,
        zoomControlOptions: {
          style: google.maps.ZoomControlStyle.SMALL,
          position: google.maps.ControlPosition.TOP_RIGHT,
        },
      }}
    >
      <Marker
        icon={icon}
        position={{ lat: 50.88089148217049, lng: 15.632858555002613 }}
      />
      {markers?.map(({ variant, items }) =>
        items?.map((item, index) => (
          <MarkerWithLabel
            key={index}
            position={{ lat: item?.lat, lng: item?.lng }}
            onMouseOver={() => setSelectedMarker(item)}
            onMouseOut={() => setSelectedMarker(null)}
            labelAnchor={new google.maps.Point(18, 18)}
            labelClass={classnames("custom-marker", {
              [`custom-marker--${variant}`]: !!variant,
            })}
            icon={{ url: "" }}
          >
            <span>{index + 1}</span>
          </MarkerWithLabel>
        ))
      )}
      {selected_marker !== null && (
        <InfoWindow
          position={{
            lat: selected_marker?.lat,
            lng: selected_marker?.lng,
          }}
        >
          <div className="window-wrapper">
            <label>{selected_marker?.name}</label>
          </div>
        </InfoWindow>
      )}
    </GoogleMap>
  );
});

export default LocationMap;
