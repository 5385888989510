import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "circle-icon";

const CircleIcon = ({ icon, variant, size }) => {
	return (
		<div
			className={classnames(mainClass, {
				[`${mainClass}--${variant}`]: !!variant,
				[`${mainClass}--${size}`]: !!size,
			})}
		>
			{icon}
		</div>
	);
};

export default CircleIcon;
