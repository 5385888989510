import "./styles.scss";

import React, { useRef, useState } from "react";
import Slider from "react-slick";
import Lightbox from "yet-another-react-lightbox";
import Fullscreen from "yet-another-react-lightbox/plugins/fullscreen";
import Zoom from "yet-another-react-lightbox/plugins/zoom";

import Button from "components/Button";

import ArrowIcon from "icons/arrow.svg";

const mainClass = "gallery-slider";

const GallerySlider = ({ data }) => {
  const dataHasCategory = data?.some(
    (item) => typeof item === "object" && item.hasOwnProperty("category")
  );
  const initialCategory = dataHasCategory
    ? data.filter((item) => item.hasOwnProperty("category"))[1].category
    : null;

  const [category, setCategory] = useState(initialCategory);
  const [lightbox, setLightbox] = useState(null);
  const [dotsInstance, setDotsInstance] = useState(null);
  const dotsReinit = useRef(false);

  const sliderRef = useRef(null);
  const sliderSettings = {
    dots: true,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 3,
    lazyLoad: false,
    arrows: false,
    draggable: false,
    appendDots: (dots) => {
      if (
        !dotsInstance ||
        dots.length !== dotsInstance.length ||
        dotsReinit.current
      ) {
        dotsReinit.current = false;

        setDotsInstance(dots);
      }

      return <div style={{ display: "none" }} />;
    },
    customPaging: (i) => (
      <button>
        <span role="presentation" onClick={() => (dotsReinit.current = true)}>
          {i + 1}
        </span>
      </button>
    ),
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleTabChange = (category) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(0, true);
    }

    setTimeout(() => {
      setCategory(category);
    }, 0);
  };

  return data?.length > 0 ? (
    <>
      <div className={mainClass}>
        {dataHasCategory && (
          <div className={`${mainClass}__tabs`}>
            {data
              .filter((item) => item.hasOwnProperty("category"))
              .map((item, index) => (
                <Button
                  variant="round"
                  active={item.category === category}
                  onClick={() => handleTabChange(item.category)}
                  key={index}
                >
                  {item.category}
                </Button>
              ))}
          </div>
        )}
        <Slider {...sliderSettings} ref={sliderRef}>
          {(dataHasCategory
            ? data.filter((item) => item.category === category)[0].slides
            : data
          ).map((item, index) => (
            <div key={index}>
              <div
                className={`${mainClass}__slide`}
                type="button"
                onClick={() => {
                  setLightbox(index);
                }}
              >
                <span className={`${mainClass}__slide-icon`}>
                  <img
                    src={require("images/expand.svg").default}
                    alt="Powiększ"
                  />
                </span>
                <div
                  className={`${mainClass}__slide-thumbnail`}
                  style={{ backgroundImage: `url(${item})` }}
                />
              </div>
            </div>
          ))}
        </Slider>
        <div className={`${mainClass}__nav`}>
          <button
            className={`${mainClass}__nav-arrow ${mainClass}__nav-arrow--prev`}
            type="button"
            onClick={() => {
              dotsReinit.current = true;

              sliderRef.current.slickPrev();
            }}
          >
            <ArrowIcon />
          </button>
          {dotsInstance?.length > 0 && (
            <ul className={`${mainClass}__nav-dots`}>{dotsInstance}</ul>
          )}

          <button
            className={`${mainClass}__nav-arrow ${mainClass}__nav-arrow--next`}
            type="button"
            onClick={() => {
              dotsReinit.current = true;

              sliderRef.current.slickNext();
            }}
          >
            <ArrowIcon />
          </button>
        </div>
      </div>
      <Lightbox
        open={lightbox !== null}
        index={lightbox !== null ? lightbox : undefined}
        close={() => setLightbox(null)}
        plugins={[Fullscreen, Zoom]}
        slides={(dataHasCategory
          ? data.filter((item) => item.category === category)[0].slides
          : data
        ).map((item) => ({
          src: item,
        }))}
      />
    </>
  ) : null;
};

export default GallerySlider;
