import "./styles.scss";

import "react-range-slider-input/dist/style.css";
import "react-image-lightbox/style.css";

import React, { useEffect, useState } from "react";
import RangeSlider from "react-range-slider-input";
import classnames from "classnames";

import Button from "components/Button";
import CircleIcon from "components/CircleIcon";

import filterApartments from "utils/filterApartments";
import statusRender from "utils/statusRender";

import HillIcon from "images/hill.jpg";
import SmileWomanImage from "images/smile-woman.jpg";

import CardIcon from "icons/card.svg";
import TouristIcon from "icons/features/tourist.svg";

const mainClass = "home-apartments";

const columns_data = [
  "Nr lokalu",
  "Piętro",
  "Metraż",
  "Pokoje",
  "Etap",
  "Status",
  "Karta lokalu",
  "Cena",
];

const getRange = (data, element) => {
  const min = Math.min(...data.map(({ data }) => data[element]));
  const max = Math.max(...data.map(({ data }) => data[element]));

  return [min, max];
};

const removeDuplicates = (data, element) => {
  return data.filter(
    (v, i, a) => a.findIndex((v2) => v.data[element] === v2.data[element]) === i
  );
};

const Apartments = ({ data }) => {
  const [stage, setStage] = useState(2);

  const apartments = data?.[`stage_${stage}`]?.map(({ id, nieruchomosci }) => ({
    id,
    data: {
      ...nieruchomosci,
      floor: nieruchomosci.floor > 0 ? nieruchomosci.floor : 0,
    },
  }));

  const rooms_available = removeDuplicates(apartments, "rooms");
  const area_available = removeDuplicates(apartments, "area")?.sort(
    (a, b) => parseFloat(a?.data?.area) - parseFloat(b?.data?.area)
  );
  const floor_available = removeDuplicates(apartments, "floor");

  const rooms_range = getRange(rooms_available, "rooms");
  const area_range = getRange(area_available, "area");
  const floor_range = getRange(floor_available, "floor");

  const [rooms, setRooms] = useState([rooms_range?.[0], rooms_range?.[1]]);
  const [area, setArea] = useState([1, area_available.length]);
  const [floor, setFloor] = useState([floor_range?.[0], floor_range?.[1]]);

  const [filtered_apartments, setFilteredApartments] = useState(
    filterApartments({ apartments, rooms, area, floor, area_available })
  );

  const sorted_apartments = filtered_apartments.sort((a, b) =>
    a.data.statusId.localeCompare(b.data.statusId)
  );

  const onSearch = () => {
    setFilteredApartments(
      filterApartments({ apartments, rooms, area, floor, area_available })
    );
  };

  /* eslint-disable */
  useEffect(() => {
    const rooms = [rooms_range?.[0], rooms_range?.[1]];
    const area = [1, area_available.length];
    const floor = [floor_range?.[0], floor_range?.[1]];

    setRooms(rooms);
    setArea(area);
    setFloor(floor);

    setFilteredApartments(
      filterApartments({ apartments, rooms, area, floor, area_available })
    );
  }, [stage]);
  /* eslint-enable */

  return (
    <section id="apartamenty-section" className={mainClass}>
      <div className="container">
        <h4>
          Wybierz kryteria apartamentów, które spełniają twoje oczekiwania:
        </h4>
        {apartments?.length > 0 && (
          <div className={`${mainClass}__options`}>
            <div className={`${mainClass}__options__item`}>
              <label>Liczba pokoi</label>
              <div className={`${mainClass}__options__item__slider`}>
                <RangeSlider
                  min={rooms_range?.[0]}
                  max={rooms_range?.[1]}
                  value={rooms}
                  onInput={(e) => setRooms(e)}
                />
                <div className={`${mainClass}__options__item__slider__steps`}>
                  <span>{rooms_range?.[0]}</span>
                  <span>{rooms_range?.[1]}</span>
                </div>
              </div>
            </div>
            <div className={`${mainClass}__options__item`}>
              <label>Powierzchnia</label>
              <div className={`${mainClass}__options__item__slider`}>
                <RangeSlider
                  min={1}
                  max={area_available.length}
                  value={area}
                  onInput={(e) => setArea(e)}
                />
                <div className={`${mainClass}__options__item__slider__steps`}>
                  <span>{area_range?.[0]}</span>
                  <span>{area_range?.[1]}</span>
                </div>
              </div>
            </div>
            <div className={`${mainClass}__options__item`}>
              <label>Piętro</label>
              <div className={`${mainClass}__options__item__slider`}>
                <RangeSlider
                  min={floor_range?.[0]}
                  max={floor_range?.[1]}
                  value={floor}
                  onInput={(e) => setFloor(e)}
                />
                <div className={`${mainClass}__options__item__slider__steps`}>
                  <span>{floor_range?.[0]}</span>
                  <span>{floor_range?.[1]}</span>
                </div>
              </div>
            </div>
            <Button onClick={onSearch}>Szukaj</Button>
          </div>
        )}
        <div className={`${mainClass}__stage`}>
          <div className={`${mainClass}__stage-apartments`}>
            Znaleziono apartamentów: {sorted_apartments?.length}
          </div>
          <div className={`${mainClass}__stage-actions`}>
            <Button
              variant="round"
              active={stage === 1}
              onClick={() => setStage(1)}
            >
              Kyriad Karkonosze
            </Button>
            <Button
              variant="round"
              active={stage === 2}
              onClick={() => {
                setStage(2);
              }}
            >
              KaiZEN
            </Button>
          </div>
        </div>
        <div className={`${mainClass}__list__wrapper`}>
          <div className={`${mainClass}__list`}>
            <div
              className={classnames(
                `${mainClass}__list__row`,
                `${mainClass}__list__row--header`
              )}
            >
              {columns_data?.map((item, index) => (
                <div key={index} className={`${mainClass}__list__row__col`}>
                  <span>{item}</span>
                </div>
              ))}
            </div>
            <div className={`${mainClass}__list__content`}>
              {sorted_apartments?.length > 0 ? (
                sorted_apartments.map(({ id, data }) => {
                  const status = statusRender(data?.statusId);

                  return (
                    <div key={id} className={`${mainClass}__list__row`}>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>{data?.nameBuilding}</span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>{data?.floor > 0 ? data.floor : "Parter"}</span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>{data?.area}</span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>{data?.rooms}</span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>
                          {data.investmentName === "Kyriad 2"
                            ? "KaiZEN"
                            : "Kyriad Karkonosze"}
                        </span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        <span>{status}</span>
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        {status !== "sprzedany" ? (
                          <a
                            href={data?.cardLink}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="small"
                          >
                            Pobierz kartę
                            <CardIcon />
                          </a>
                        ) : null}
                      </div>
                      <div className={`${mainClass}__list__row__col`}>
                        {status !== "sprzedany" ? (
                          <a
                            className={`${mainClass}__list__row__col--button`}
                            href={`/#kontakt?apartment=${id}`}
                          >
                            Zapytaj o cenę
                          </a>
                        ) : null}
                      </div>
                    </div>
                  );
                })
              ) : (
                <div className={`${mainClass}__list__row`}>
                  <p>Nie znaleziono apartamentów.</p>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={`${mainClass}__banner`}>
          <div className={`${mainClass}__banner__left`}>
            <img src={SmileWomanImage} alt="" />
          </div>
          <div className={`${mainClass}__banner__right`}>
            <CircleIcon icon={<TouristIcon />} />
            <div className={`${mainClass}__banner__right__text`}>
              <img src={HillIcon} alt="" />
              <h3>
                Zainwestuj w regionie odwiedzanym przez <span>1,5 mln</span>{" "}
                turystów rocznie!
              </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Apartments;
