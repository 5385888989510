const statusRender = (status) => {
  switch (status) {
    case "1":
      return "dostępny";
    case "2":
      return "zarezerwowany";
    case "3":
    case "4":
    case "5":
    case "6":
    case "7":
    case "8":
      return "sprzedany";
    case "9":
      return "wstrzymany";
    default:
      return null;
  }
};

export default statusRender;
