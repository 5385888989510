import "./styles.scss";
import React from "react";

import LocationMap from "components/LocationMap";
import SectionHeader from "components/SectionHeader";

import legend_data from "libs/legend_data";

import MenImage from "images/location-men.jpg";

import CitiesIcon from "icons/cities.svg";

const mainClass = "home-location";

const stats_data = [
  {
    label: "Turystów rocznie",
    value: "1,5 mln",
  },
  {
    label: "Zabytków i muzeów w pobliżu",
    value: "~40",
  },
  {
    label: "Wodospady i wiele górskich szczytów",
    value: "4",
  },
  {
    label: "Do Szklarskiej Poręby",
    value: "10 min",
  },
  {
    label: "Wyciągów narciarskich",
    value: "29",
  },
  {
    label: "Narciarskich tras biegowych",
    value: "150 km",
  },
  {
    label: "Tras rowerowych",
    value: "300 km",
  },
  {
    label: "Szlaków pieszych",
    value: "400 km",
  },
];

const closest_cities = {
  icon: <CitiesIcon />,
  label: "Miasta",
  items: [
    {
      name: "Szklarska Poręba - 10 min",
    },
    {
      name: "Karpacz - 25 min",
    },
    {
      name: "Warszawa - 5 h",
    },
    {
      name: "Wrocław - 2h",
    },
    {
      name: "Praga - 2,5 h",
    },
    {
      name: "Drezno - 2,5 h",
    },
  ],
};

const formatted_legend = [...legend_data, closest_cities];

const Location = () => {
  return (
    <section className={mainClass} id="lokalizacja-section">
      <div className="container">
        <SectionHeader title="Lokalizacja" />
        <div className={`${mainClass}__post`}>
          <div className={`${mainClass}__post__text`}>
            <p>
              Nasze inwestycje położone są w pobliżu Karkonoskiego Parku
              Narodowego, zaledwie 10 min od Szklarskiej Poręby i 30 min od
              Karpacza. Z pokojów rozpościerają się przepiękne widoki na
              Śnieżkę, Szrenicę oraz okoliczne lasy i polany. Region nie bez
              przyczyny odwiedzany jest przez 1,5 mln turystów rocznie, obfituje
              bowiem w liczne atrakcje, stoki narciarskie, unikalne trasy
              rowerowe i długie kilometry malowniczych szlaków turystycznych,
              zapewniających aktywny wypoczynek i atrakcyjny pobyt przez cały
              rok. Miejsce jest doskonałą bazą wypadową na widokowe górskie
              szlaki, zwiedzanie licznych zamków, spacery nad pobliskie
              wodospady, czy na jednodniowe wycieczki do Czech, w tym czeskiej
              Pragi oddalonej zaledwie 2,5 h drogi od hotelu.
            </p>
          </div>
          <div className={`${mainClass}__post__image`}>
            <img src={MenImage} alt="" />
          </div>
        </div>
        <div className={`${mainClass}__stats`}>
          {stats_data?.map(({ label, value }, index) => (
            <div key={index} className={`${mainClass}__stats__item`}>
              <div key={index} className={`${mainClass}__stats__item__wrapper`}>
                <h3>{value}</h3>
                <label>{label}</label>
              </div>
            </div>
          ))}
        </div>
        <div className={`${mainClass}__map`}>
          <div className={`${mainClass}__map__image`}>
            <LocationMap />
          </div>
          <div className={`${mainClass}__map__legend`}>
            {formatted_legend?.map(({ icon, label, items }, index) => (
              <div key={index} className={`${mainClass}__map__legend__col`}>
                {icon}
                <div className={`${mainClass}__map__legend__col__list`}>
                  <label>{label}</label>
                  <ul>
                    {items?.map(({ name }, index) => (
                      <li key={index}>{name}</li>
                    ))}
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Location;
