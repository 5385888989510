import "./styles.scss";

import React from "react";
import classnames from "classnames";

const mainClass = "section-header";

const SectionHeader = ({ section_name, title, text, color, variant }) => {
  return (
    <div
      className={classnames(mainClass, {
        [`${mainClass}--${color}`]: !!color,
        [`${mainClass}--${variant}`]: !!variant,
      })}
    >
      {!!section_name && <span>{section_name}</span>}
      <h2>{title}</h2>
      {!!text && <p>{text}</p>}
    </div>
  );
};

export default SectionHeader;
