import "./styles.scss";
import React from "react";

import SectionHeader from "components/SectionHeader";
import FeaturesGrid from "components/FeaturesGrid";

import AquaparkIcon from "icons/features/aquapark.svg";
import JacuzziIcon from "icons/features/jacuzzi.svg";
import SpaIcon from "icons/features/spa.svg";
import SaunaIcon from "icons/features/sauna.svg";
import GymIcon from "icons/features/gym.svg";
import LobbyIcon from "icons/features/lobby.svg";
import ParkingIcon from "icons/features/parking.svg";
import ConferenceIcon from "icons/features/conference-room.svg";
import RestaurantIcon from "icons/features/restaurant.svg";
import BarIcon from "icons/features/bar.svg";
import KidsZoneIcon from "icons/features/kids-zone.svg";
import TarraceIcon from "icons/features/tarrace.svg";
import CarChargerIcon from "icons/features/car-charger.svg";

const mainClass = "home-facilities";

const business_model_data = [
  {
    icon: <AquaparkIcon />,
    text: "Park wodny",
  },
  {
    icon: <JacuzziIcon />,
    text: "Jacuzzi",
  },
  {
    icon: <SpaIcon />,
    text: "SPA",
  },
  {
    icon: <SaunaIcon />,
    text: "Sauna",
  },
  {
    icon: <GymIcon />,
    text: "Siłownia",
  },
  {
    icon: <LobbyIcon />,
    text: "Recepcja",
  },
  {
    icon: <ParkingIcon />,
    text: "Parking",
  },
  {
    icon: <ConferenceIcon />,
    text: "Konferencje",
  },
  {
    icon: <RestaurantIcon />,
    text: "Restauracja",
  },
  {
    icon: <BarIcon />,
    text: "Bar",
  },
  {
    icon: <KidsZoneIcon />,
    text: "Animacje dla dzieci",
  },
  {
    icon: <TarraceIcon />,
    text: "Taras widokowy",
  },
  {
    icon: <CarChargerIcon />,
    text: "Ładowarka do samochodów elektrycznych",
  },
];

const Facilities = () => {
  return (
    <section className={mainClass} id="udogodnienia-section">
      <div className="container">
        <SectionHeader title="Udogodnienia" />
        <FeaturesGrid data={business_model_data} whiteIcons />
      </div>
    </section>
  );
};

export default Facilities;
