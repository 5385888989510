import "./styles.scss";

import React from "react";
import classnames from "classnames";

import SectionHeader from "components/SectionHeader";
import FeaturesGridV2 from "components/FeaturesGridV2";

import PassiveIcon from "icons/features/passive.svg";
import AntiInflationIcon from "icons/features/anti-inflation.svg";
import CalendarIcon from "icons/features/calendar.svg";
import VatReturnIcon from "icons/features/vat-return.svg";
import BikerIcon from "icons/features/biker.svg";

import DealIcon from "icons/features/deal-green.svg";
import DocumentsIcon from "icons/features/documents-green.svg";
import HotelIcon from "icons/features/hotel-green.svg";

const mainClass = "business-model";

const business_model_data = [
  {
    icon: <PassiveIcon />,
    title: "Pasywny dochód nawet 8%*",
  },

  {
    icon: <CalendarIcon />,
    title: "Od 12 do 30 dni pobytu właścicielskiego w roku*",
  },
  {
    icon: <VatReturnIcon />,
    title: "Zwrot VAT, również dla osób fizycznych",
  },

  {
    icon: <AntiInflationIcon />,
    title: "Tarcza antyinflacyjna. Stopa zwrotu indeksowana w czasie",
  },
  {
    icon: <HotelIcon />,
    title: "Operator hotelowy",
  },
  {
    icon: <DealIcon />,
    title: "Umowa 10 lat",
  },
  {
    icon: <DocumentsIcon />,
    title: "Księga Wieczysta",
  },
  {
    icon: <BikerIcon />,
    title: "Inwestycja w popularnym regionie - 1,5 mln turystów rocznie",
  },
];

const BusinessModel = () => {
  return (
    <section id="model-biznesowy-section" className={mainClass}>
      <div className={classnames(`${mainClass}__main`)}>
        <div className="container">
          <SectionHeader
            title="Model biznesowy"
            text="Zyskaj pewną inwestycję i własny apartament oraz komfortowe miejsce wypoczynku dla siebie i Twojej rodziny. Nasz model biznesowy zaspokaja potrzeby najbardziej wyrafinowanych inwestorów:"
          />
          <FeaturesGridV2 data={business_model_data} />
          <div className={`${mainClass}__footer`}>
            <p>*Stała stopa zwrotu:</p>
            <p>
              Kyriad Karkonosze: 8% - 5 dni pobytu w sezonie wysokim, 7 dni
              pobytu poza sezonem wysokim; 7,5% - 5 dni pobytu w sezonie
              wysokim, 14 dni pobytu poza sezonem wysokim; 7% - 7 dni pobytu w
              sezonie wysokim, 21 dni pobytu poza sezonem wysokim.
            </p>
            <p>
              KaiZEN: 8% - 5 dni pobytu w sezonie wysokim, 7 dni pobytu poza
              sezonem wysokim; 7,5% - 7 dni pobytu w sezonie wysokim, 14 dni
              pobytu poza sezonem wysokim; 7% - 9 dni pobytu w sezonie wysokim,
              21 dni pobytu poza sezonem wysokim.
            </p>
          </div>
        </div>
      </div>
      {/* <div className={`${mainClass}__extras`}>
				<div className="container">
					<FeaturesGrid data={extras_data} whiteIcons />
				</div>
			</div> */}
    </section>
  );
};

export default BusinessModel;
