const priceFormatted = (price) => {
  if (!price) return undefined;

  const price_formatted = price.toLocaleString("pl-PL").replace(",", ".");

  const price_array = price_formatted.split(".");

  const price_padded =
    price_array.length > 1
      ? `${price_array[0]}.${price_array[1].padEnd(2, "0")}`
      : price_array[0];

  const price_with_currency = `${price_padded} zł`;

  return price_with_currency;
};

export default priceFormatted;
