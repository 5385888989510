import "./styles.scss";
import React from "react";

import SectionHeader from "components/SectionHeader";
import GallerySlider from "components/GallerySlider";

const mainClass = "home-aquapark";

const gallery_data = [
  require("images/aquapark/swiming-pool2.jpg").default,
  ...new Array(3)
    .fill()
    .map(
      (_, index) => require(`images/aquapark/aqua-${index + 1}.jpg`).default
    ),
];

const Aquapark = () => {
  return (
    <section className={mainClass}>
      <SectionHeader
        title="Park wodny"
        text="Jeden z największych Parków wodnych w Karkonoszach."
		variant="margin-small"
      />
      <GallerySlider data={gallery_data} />
    </section>
  );
};

export default Aquapark;
