import "./styles.scss";

import React from "react";

const StageHeader = ({ title, subtitle, notice, description }) => {
  return (
    <div className="stage-header">
      <h3>{title}</h3>
      {subtitle && <h4>{subtitle}</h4>}
      {notice && (
        <div className="stage-header__notice">
          <div
            className="stage-header__notice-inner"
            dangerouslySetInnerHTML={{ __html: notice }}
          />
        </div>
      )}
      {description && (
        <div
          className="stage-header__description"
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </div>
  );
};

export default StageHeader;
